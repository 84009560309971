body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  width: 100%;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  gap: 40px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(233, 233, 233);
}

.logo-cloee {
  width: 200px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  outline: none;
  border: none;
}

.logo-cloee:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.logo-mogar {
  width: 180px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  outline: none;
  border: none;
}

.logo-mogar:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.text-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 50px;
  margin-bottom: 10px;
}

.text-container p {
  margin: 0;
  line-height: 1.5;
  font-size: 14px;
}

.word {
  font-weight: bold;
  margin: 0;
  padding: 0;
  margin-right: 1px;
  font-size: 19.5px;
  color: #bd2029;
  line-height: 1.1;
}

.chat-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-parent {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 50%;
  background-color: white;
  overflow: hidden;
  margin: auto;
}

.logo-messages-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  overflow: hidden;
}

.message-container {
  flex: 1;
  overflow-y: auto;
  margin-right: -10px;
}

.message {
  display: flex;
  flex-direction: row;
  color: white;
  border-radius: 10px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 8px;
  margin-bottom: 15px;
}

.user-message {
  justify-content: flex-end;
}

.bot-message {
  justify-content: flex-start;
  padding-left: 0;
}

.user-message p, .bot-message p {
  margin: 0;
  padding: 0;
}

.user-icon {
  height: 32px;
  width: 32px;
  margin-left: 8px;
  margin-top: 2px;
}

.cloee-icon {
  height: 32px;
  width: 32px;
  margin-top: -4px;
}

.cloee-logo-top {
  height: 65px;
}

.bot-chat-text, .user-chat-text {
  max-width: 70%;
  padding: 10px;
  padding-top: 0;
  border-radius: 8px;
  font-size: 16px;
  line-height: 1.5;
}

.bot-chat-text {
  background-color: #ffffff;
  color: #333;
}

.bot-chat-text > :first-child {
  margin-top: 0;
  padding-top: 0;
}

.user-chat-text {
  background-color: #f2f2f2;
  border: none;
  text-align: left;
  color: #000000;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 6px;
  border-radius: 10px;
}

.send-icon {
  font-size: 24px;
  transform: rotate(45deg);
}

.chat-input {
  display: flex;
  padding: 10px;
  background-color: transparent;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 10px;
  border: 1px solid #e0e0e0;
  outline: none;
}

.chat-input input:disabled {
  background-color: #eaeaea;
  cursor: not-allowed;
}

.chat-input button {
  padding: 0px 18px;
  background-color: #bd2029;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

.chat-input button:hover {
  background-color: #de333b;
}

.chat-input button:disabled {
  background-color: #a7a7a7;
  cursor: not-allowed;
}

.loader {
  border: 4px solid rgba(243, 243, 243, 0.3);
  border-top: 4px solid #bd2029;
  border-radius: 50%;
  width: 25px;
  min-height: 25px;
  animation: spin 1.4s linear infinite;
}

.user-info-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  width: 70%;
}

.user-info-form input {
  border-radius: 5px;
  border: 1px solid grey;
  outline: none;
  padding: 5px;
}

.user-info-form button {
  padding: 6px;
  background-color: #bd2029;
  color: rgb(246, 246, 246);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

.user-info-form button:hover {
  background-color: #de333b;
}

/* table styles */
.bot-chat-text table {
  border-collapse: separate;
  border-spacing: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  overflow: auto;
}

.bot-chat-text th, .bot-chat-text td {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: .35rem .50rem;
  text-align: left;
  font-size: 14px;
}

.bot-chat-text th {
  color: #000000;
  background-color: rgba(0, 0, 0, 0.1);
  border-right: 0;
  padding: .5rem .50rem;
}

.bot-chat-text th:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.bot-chat-text th {
  border-bottom: none;
}

.bot-chat-text td {
  border-bottom: 0px solid rgba(0, 0, 0, 0.15);
}

.bot-chat-text td {
  border-right: 0px solid rgba(0, 0, 0, 0.15);
}

.bot-chat-text td:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.bot-chat-text tr:first-child th:first-child {
  border-top-left-radius: 10px; /* Rounded top left corner */
}
.bot-chat-text tr:first-child th:last-child {
  border-top-right-radius: 10px; /* Rounded top right corner */
}
.bot-chat-text tr:last-child td:first-child {
  border-bottom-left-radius: 10px; /* Rounded bottom left corner */
}
.bot-chat-text tr:last-child td:last-child {
  border-bottom-right-radius: 10px; /* Rounded bottom right corner */
}

.bot-chat-text tr:last-child td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15); /* Adds bottom border back on all cells of the last row */
}
/*Table style ending*/


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .chat-parent {
    display: flex;
    height: 90vh;
    justify-content: center;
    width: 96%;
  }

  .user-message {
    max-width: 92%;
  }

  .logo-container {
    gap: 30px;
  }

  .logo-cloee {
    width: 160px;
  }

  .logo-mogar {
    width: 140px;
  }

  .logo-messages-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .text-container {
    margin-right: 0px;
  }

  .chat-input {
    padding-left: 0;
    padding-right: 0;
  }

  .bot-icon {
    height: 24px;
    min-width: 24px;
  }

  .cloee-icon {
    width: 30px;
    height: 30px;
    margin-top: -2px;
  }

  .loader {
    margin-left: 10px;
  }

  .message-container {
    height: 83%;
  }

  .user-info-form {
    width: 100%;
  }
}